import CommonService from './common.service';
import { generateQueryParams } from 'utils';
import { _Object } from 'utils/interfaces';

class ProductService extends CommonService {
  /*Products */
  async getProducts(params: _Object | string) {
    return await this.get(`products?${generateQueryParams(params)}`)
  }
  async getProductSearch(params: _Object | string) {
    return await this.get(`products/search?${generateQueryParams(params)}`)
  }
  async getProductDetails(id: string) {
    return await this.get(`products/${id}`)
  }
  async createProduct(parmas: { [key: string]: boolean | string | null }) {
    return await this.post('products', parmas)
  }
  async createProductSKU(parmas: { [key: string]: boolean | string | null }) {
    return await this.post('products/genrate-sku', parmas)
  }
  async createProductBarcode(parmas: { [key: string]: boolean | string | null }) {
    return await this.post('products/genrate-barcode', parmas)
  }
  async updateProduct(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`products/${id}`, params)
  }
  async deleteProduct(id: string) {
    return await this.delete(`products/${id}`)
  }

  /*ProductAttributes*/
  async getProductAttributes(params: _Object | string) {
    return await this.get(`products-attributes?${generateQueryParams(params)}`)
  }
  async getProductAttributesDetails(id: string) {
    return await this.get(`products-attributes/${id}`)
  }
  async createProductAttribute(parmas: { [key: string]: boolean | string }) {
    return await this.post('products-attributes', parmas)
  }
  async updateProductAttribute(id: string, params: { [key: string]: boolean | string }) {
    return await this.put(`products-attributes/${id}`, params)
  }
  async deleteProductAttribute(id: string) {
    return await this.delete(`products-attributes/${id}`)
  }

  /*ProductAttributesValues*/
  async getProductAttributeValues(params: _Object, id: string | string) {
    return await this.get(`products-attributes/${id}/values?${generateQueryParams(params)}`)
  }
  async getProductAttributeValueDetails(id: string) {
    return await this.get(`products-attributes/${id}/values`)
  }
  async createProductAttributeValue(id: string, parmas: _Object | string) {
    return await this.post(`products-attributes/${id}/values`, parmas)
  }
  async updateProductAttributeValue(id: string, params: _Object) {
    return await this.put(`products-attributes/${id}/values`, params)
  }
  async deleteProductAttributeValue(id: string, attributeId: string) {
    return await this.delete(`products-attributes/${id}/values/${attributeId}`)
  }
  async createProductAttributeAndValue(params: _Object, id: string | string) {
    return await this.post(`products/${id}/attributes`, params)
  }

  /* Tax */
  async taxRate(params: _Object) {
    return await this.get(`tax-rates?${generateQueryParams(params)}`)
  }
  async taxRateDetails(id: string) {
    return await this.get(`tax-rates/${id}`)
  }
  async createTaxRate(id: string, parmas: _Object | string) {
    return await this.post(`tax-rates/${id}`, parmas)
  }
  async updateTaxRate(id: string, params: _Object) {
    return await this.put(`tax-rates/${id}`, params)
  }
  async deleteTaxRate(id: string) {
    return await this.delete(`tax-rates/${id}`)
  }

  async importCSV(params: any) {
    const data = new FormData();
    data.append('file', params[0]);

    return await this.post('products/import', data);
  }


}

export const productService = new ProductService();
